
<div  class="row">

    <div class="col-sm-12">

        <div class="post-share-content mb-30">

            <div class="timeline-story-header d-flex align-items-center">
                <img src="assets/img/empty.png" width="50" height="50" alt="image"
                 class="rounded-circle"><div class="info ml-3">
                     <h6 ><a href="javascript:void(0)">{{appservice.Share_Data.ledger_Name}}</a> </h6><span style="color: grey;" class="d-block">{{appservice.Share_Data.Patient_ID}}</span></div></div>


                     <div class="timeline-story-header d-flex align-items-center"  >
                        <span  class="bx bx-phone mr-2" style="text-align: end;
                        font-weight: 500;
                        width: 35px;
                        font-size: 22px;"
                        role="status" aria-hidden="true"></span><div class="info ml-3" style="width: 100%; padding: 10px;">
                            <input  
                            type="number" style="width: 100%;
                            border: none;
                            border-bottom: black;
                            border-bottom: 1px solid #eeeeee;"
                            class="form-control form-control-sm" [(ngModel)]="appservice.Share_Data.Phone_Number"
                            name="Contact_No" #Contact_No="ngModel">
                             </div></div>


                             <div class="timeline-story-header d-flex align-items-center"  >
                                <span  class="bx bx-envelope mr-2" style="text-align: end;
                                font-weight: 500;
                                width: 35px;
                                font-size: 22px;"
                                role="status" aria-hidden="true"></span><div class="info ml-3" style="width: 100%; padding: 10px;">
                                    <input  
                                    type="text" style="width: 100%;
                                    border: none;
                                    border-bottom: black;
                                    
                                    border-bottom: 1px solid #eeeeee;" placeholder="Email ID"
                                    class="form-control form-control-sm" [(ngModel)]="appservice.Share_Data.Email_ID"
                                    name="Email_ID" #Email_ID="ngModel">
                                     </div></div>
                                     <div class="col-md-12 text-center" style="display: inline-flex;">
                   
                                        <div class="form-check"  style="padding-left: 35px;">
                    
                                          <input class="form-check-input" type="radio" 
                                          name="With_header"  
                                          [(ngModel)]="select_mode"
                                               #With_header="ngModel" checked
                                          id="With_header" style="padding-left: 10px; margin-top: 4px;margin-left: -14px; " value="With Header">
                                          <label class="form-check-label" for="With_header">&nbsp; With Header</label>
                                      </div>
                                      
                                     
                                      <div class="form-check"  style="padding-left: 35px;">
                    
                                          <input class="form-check-input" type="radio" 
                                          name="Without_header" id="Without_header" 
                                          [(ngModel)]="select_mode"
                                               #Without_header="ngModel"
                                          style="padding-left: 10px; margin-top: 4px;margin-left: -14px; " value=" Without Header">
                                          <label class="form-check-label" for="Without_header">&nbsp; Without Header</label>
                                      </div>
                                      </div>
                               

            <div class="post-share-footer d-flex align-items-center justify-content-between  pt-4" style="border:none;margin: -20px;">
                
             
                
               
                         
                         <button style="    width: 37%;
                         margin-left: 31px;" class="btn btn-danger rounded-pil" 
              
                         [disabled]="btndisable1" (click)="Send_Patient_Email()" >
           
           
                             <span *ngIf="btndisable1==true" class="spinner-border spinner-border-sm"
                                 role="status" aria-hidden="true"></span>
                             <i *ngIf="btndisable1==false" class='bx bx-mail-send'></i>
                              Mail </button>

                        <button style="width: 40%;
                        margin-right: 30px;" class="btn btn-success rounded-pil" 
              
                        [disabled]="btndisable" (click)="Send_Report_SMS()"  >
          
          
                            <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            <i *ngIf="btndisable==false" class='bx bxl-whatsapp'></i>
                             Whatsapp</button>

            </div>
        </div>

        <div class="post-share-content mb-30 hidden">

            <div class="timeline-story-header d-flex align-items-center">
                <img src="assets/img/empty.png" width="50" height="50" alt="image"
                 class="rounded-circle"><div class="info ml-3">
                     <h6 ><a href="javascript:void(0)">{{appservice.Share_Data.R_Name}}</a> </h6><span style="color: grey;" class="d-block">{{appservice.Share_Data.Qualification}}</span></div></div>


                     <div class="timeline-story-header d-flex align-items-center"  >
                        <span  class="bx bx-phone mr-2" style="text-align: end;
                        font-weight: 500;
                        width: 35px;
                        font-size: 22px;"
                        role="status" aria-hidden="true"></span><div class="info ml-3" style="width: 100%; padding: 10px;">
                            <input  
                            type="number" style="width: 100%;
                            border: none;
                            border-bottom: black;
                            border-bottom: 1px solid #eeeeee;"  placeholder="Contact No"
                            class="form-control form-control-sm" [(ngModel)]="appservice.Share_Data.Mobile_No"
                            name="Mobile_No" #Mobile_No="ngModel">
                             </div></div>


                             <div class="timeline-story-header d-flex align-items-center"  >
                                <span  class="bx bx-envelope mr-2" style="text-align: end;
                                font-weight: 500;
                                width: 35px;
                                font-size: 22px;"
                                role="status" aria-hidden="true"></span><div class="info ml-3" style="width: 100%; padding: 10px;">
                                    <input  
                                    type="text" style="width: 100%;
                                    border: none;
                                    border-bottom: black;
                                    
                                    border-bottom: 1px solid #eeeeee;" placeholder="Email ID"
                                    class="form-control form-control-sm" [(ngModel)]="appservice.Share_Data.Contact_Email"
                                    name="Contact_Email" #Contact_Email="ngModel">
                                     </div></div>
                             
           
            <div class="post-share-footer d-flex align-items-center justify-content-between  pt-4" style="border:none;margin: -20px;">
                
             

                
                    <button style="width: 28%;" class="btn btn-info rounded-pil" 
              
                    [disabled]="btndisable" (click)="Send_Report_SMS()"  >
      
      
                        <span *ngIf="btndisable==true"  class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                        <i *ngIf="btndisable==false" class='bx bx-message-rounded-dots'></i>
                         SMS</button>

                        <button style="width: 40%;" class="btn btn-success rounded-pil" 
              
                        [disabled]="btndisable" (click)="Send_Report_SMS()"  >
          
          
                            <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                            <i *ngIf="btndisable==false" class='bx bxl-whatsapp'></i>
                             Whatsapp</button>

                             <button style="width: 28%;" class="btn btn-danger rounded-pil" 
              
                             [disabled]="btndisable1" (click)="Send_Referer_Email()"  >
               
               
                                 <span *ngIf="btndisable1==true" class="spinner-border spinner-border-sm"
                                     role="status" aria-hidden="true"></span>
                                 <i *ngIf="btndisable1==false" class='bx bx-mail-send'></i>
                                  Mail </button>
            </div>
        </div>

 
    </div>
 
  </div>
  
