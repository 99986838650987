import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
declare let $: any;

@Component({
  selector: 'app-normal-value-update',
  templateUrl: './normal-value-update.component.html',
  styleUrls: ['./normal-value-update.component.scss']
})
export class NormalValueUpdateComponent implements OnInit {

  ngOnInit(): void {
  }
  Rows = [];
  cols: any[];
  Selected_Rights = "";
  Selected_Module = ""
  btndisable: boolean = false;
  public rows = [];
  Row = [];

  filter: boolean = false;
  headers;
  data;
  add: any = {};
  add1: any = {};

  public access_tocken = "";

  constructor(public confirmationService: ConfirmationService, private toastr: ToastrService, public appservice: AppService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
   
  this.add.Age_From="0";
  this.add.Age_To="0"
  }



  get_Reference()
  {
    if(this.appservice.Test_Row_.Input_type=='Numeric')
    {
      if(this.add.Gender=="Any")
      {
        this.add.Reference_Value=this.add.Min_Value+"- "+this.add.Max_Value;

      }else{
         this.add.Reference_Value=this.add.Gender+" : "+this.add.Min_Value+"- "+this.add.Max_Value;
      }
    }else
    {
      this.add.Reference_Value=this.add.Gender+" : "+this.add.Text_;

    }
  }
  onEdit(item) {
    this.appservice.Button_Name="Update"
    this.add = item;
    this.appservice.Old_Reference_Value=item.Reference_Value;

  }


  onDelete(item) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete press Yes?',
      accept: () => {
        this.Delete_(item)
      }
    });
  }

  Delete_(item) {
    this.appservice.get("Api/master/delete_Test_Details?ID=" + item).subscribe((res: any) => {

      this.appservice.get_Normal_Value();
    });
  }
  get_Year()
  {
    this.add.Day_From=(this.add.Age_From*365);
    this.add.Day_To=(this.add.Age_To*365);

  }
  public get_data_Rows=[]
  get_Test_Details_Entry(Bill_No) {
    this.get_data_Rows = [];
    this.appservice.get("Api/Transaction/get_Lab_Test_Entry?Bill_No=" +Bill_No).subscribe((res: any) => {

      this.get_data_Rows = JSON.parse(res).record;
      for (var i = 0; i < this.get_data_Rows.length; i++) {
        var stringValue = this.get_data_Rows[i].isAbnormal;
        this.get_data_Rows[i].isAbnormal = stringValue.toLowerCase() == "true";
      }
      });
  
    }
 
    get_Test_Details_Result(ID) {
  
      this.get_data_Rows = [];
      this.appservice.get("Api/Transaction/get_Lab_Test_Result_Entry?Bill_No=" +ID).subscribe((res: any) => {
      this.get_data_Rows = JSON.parse(res).record;
      for (var i = 0; i < this.get_data_Rows.length; i++) {
        var stringValue = this.get_data_Rows[i].isAbnormal;
        this.get_data_Rows[i].isAbnormal = stringValue.toLowerCase() == "true";
      }
        });
    
      }

  public addValidation: boolean = false;
  isadd = "0";
  error_Msg=false;
 public msg="";
  addData() {
if((this.add.Gender==undefined)||(this.add.Gender==""))
{
  this.msg="Please Select Gender";
  return;
}


if((this.add.Day_To==undefined)||(this.add.Day_To==""))
{
  this.msg="Please enter Maximum Age";
  return;
}
if(this.appservice.Test_Row_.Input_type=="Text")
{
  if(this.add.Text_==undefined)
  {
  this.msg="Please enter Text";
  return;
  }
}
if(this.appservice.Test_Row_.Input_type=="Numeric")
{
  if(this.add.Min_Value==undefined)
  {
  this.msg="Please enterLower value";
  return;
  }


}
if(this.appservice.Test_Row_.Input_type=="Numeric")
{
  if(this.add.Max_Value==undefined)
  {
    this.msg="Please enter Upper value";
    return;
  }
}
    this.add.Test_ID=this.appservice.Test_Row_.ID
    this.add.Company_ID = this.appservice.Company;
    this.add.Company = this.appservice.Company;
    this.add.Table_Name = "Testmaster_Details"
    this.add.Company = this.appservice.Company;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.appservice.Normal_Value_Update=true;
    this.btndisable = true;
    this.http.post(this.appservice.Server_URL + 'api/master/Post_Test_Details', this.add, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.msg='';
          this.btndisable = false;
          this.appservice.Button_Name="Save"

          if (val == "True") {
            this.toastr.success("Details Salved Success", 'Msg');
            this.appservice.get_Normal_Value();
            this.appservice.Normal_Value_Update=true;
            
            this.add.Gender='';
            this.add.Age_From='';
            this.add.Age_To='';
            this.add.Day_From='';
            this.add.Day_To='';
            this.add.Min_Value='';
            this.add.Max_Value='';
            this.add.Text_='';
            this.add.Reference_Value='';

        
          }
          else {
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
        });
  }


  addData1() {
   
        this.add.Test_ID=this.appservice.Test_Row_.ID
        this.add.Company_ID = this.appservice.Company;
        this.add.Company = this.appservice.Company;
        this.add.Table_Name = "Testmaster_Details"
        this.add.Company = this.appservice.Company;
        this.add.Default_Result = this.appservice.Test_Row_.Default_Result;
        
        this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
        this.appservice.Normal_Value_Update=true;
        this.btndisable = true;
        this.http.post(this.appservice.Server_URL + 'api/master/Post_Test_Details1', this.add, { headers: this.headers })
          .subscribe(
            (val: string) => {
              this.msg='';
              this.btndisable = false;
              this.appservice.Button_Name="Save"
  
              if (val == "True") {
                this.toastr.success("Details Salved Success", 'Msg');
                this.add.Gender='';
                this.add.Age_From='';
                this.add.Age_To='';
                this.add.Day_From='';
                this.add.Day_To='';
                this.add.Min_Value='';
                this.add.Max_Value='';
                this.add.Text_='';
                this.add.Reference_Value='';
    
            
              }
              else {
                this.toastr.error(val, "Error", { timeOut: 3000 });
              }
            },
            response => {
              this.toastr.error('Error ', response, {
                timeOut: 3000
              });
            });
      }
}














