<div class="row">
    <div class="col-md-12 nopad">
        <div class="card content ">

            <div class=" Head ">
                <div class="card-header  d-flex justify-content-between align-items-center">
                    <h3>Bank Details</h3>

                    <div class="dropdown">



                        <a class="dropdown-toggle " (click)=" addReset()"  data-toggle="tooltip"
                            data-placement="bottom" title="Add New" routerlink="">
                            <i class='bx bx-list-plus'></i>
                        </a>

                        <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more"
                            aria-haspopup="true" aria-expanded="false">
                            <i class='bx bx-dots-vertical'></i>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                <i class='bx bx-download'></i> Exprot
                            </a>
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                <i class='bx bx-loader-circle'></i> Refresh
                            </a>
                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                <i class='bx bx-bulb'></i> Page Tips
                            </a>

                        </div>
                    </div>
                </div>

            </div>

            <div class="content_body">
                <div>
                     <p-table responsiveLayout="scroll" #dt class="table table-striped custom-table" [value]="Rows" [columns]="cols"
                        [paginator]="true" [rows]="10" [resizableColumns]="true" sortMode="multiple"
                        selectionMode="single">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let col of cols" [pSortableColumn]="col.field" width="{{col.width}}"
                                    style="white-space: pre">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order">
                                    </p-sortIcon>
                                </th>
                                <th width=90>Action</th>
                            </tr>

                            <tr>
                                <th *ngFor="let col of cols" [ngSwitch]="col.field">
                                    <input style="height: 30px" class="form-control" pInputText type="text"
                                        (input)="dt.filter($event.target.value, col.field, 'contains')">
                                </th>
                                <th width=90></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-car>
                            <tr [pSelectableRow]="rowData">
                                <td *ngFor="let col of cols">
                                    {{car[col.field]}}
                                </td>
                                <td class="text-right" style="white-space: pre">
                                    <a href="javascript:" style="font-size: 18px;padding: 5px" title="Edit"
                                        (click)="onEdit(rowData)"><i class="bx bxs-pencil m-r-5"></i>

                                    </a>
                                    <a title="Delete" style="font-size: 18px;padding: 5px;color: firebrick"
                                        (click)="onDelete(rowData.ID)"><i class="bx bxs-trash m-r-5"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>


