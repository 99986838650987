<!-- Start Sidemenu Area -->
<div  class="sidemenu-area">
    <div class="sidemenu-header " style="    box-shadow: 0 16px 24px -12px rgb(0 0 0 / 56%) !important;">
        <a class="navbar-brand d-flex align-items-center">
            <img class="hidden"  style="height: 36px;width:36px;" src="assets/img/{{appservice.Logo_location}}.png" alt="image">

            <img  (click)="get_Home()"style="height: 60px;" src="assets/img/Std.png" alt="image">

           
        </a>

        <div class="burger-menu d-none d-lg-block" *ngIf="appservice.header_hide==false">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>

    <div class="sidemenu-body right_shade" style="background-image: url(../../assets/img/Side1.jpg); " >
        <ul    class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar  style="scrollbar-width: 12px; background-color:#ffffffeb;padding-top: 0px;" > 
           
            <div   *ngFor="let label of appservice.Label_Menu_Rows">




                <li    [class]="ac==label.ID?'active nav-item':'nav-item'" routerLinkActive="active mm-active" >
                    <a  href="#" class="collapsed-nav-link nav-link" aria-expanded="true" (click)="ac=(label.ID==ac)?0:label.ID" data-toggle="collapse" >
                        <span class="icon"><i class='{{label.Icon}}'></i></span>
                        <span class="menu-title">{{label.Display_Name}}</span>
    
                    </a>
    
                    <ul class="sidemenu-nav-second-level "  *ngIf="ac==label.ID" >
                        <li  *ngFor="let child of appservice.get_child(label.ID)"  class="nav-item" routerLinkActive="active">
                            <a routerLink="{{child.Route_Link}}" class="nav-link">
                                <span class="icon"><i class='{{child.Icon}}'></i></span>
                                <span class="menu-title">{{child.Display_Name}}</span>
                            </a>
                        </li>
                      
                    </ul>
                </li>


                

         </div>
           
        </ul>
    
    </div>
</div>
<!-- End Sidemenu Area -->